import {
    parse,
    format,
    startOfWeek,
    lastDayOfMonth,
    addDays,
    eachDayOfInterval,
    areIntervalsOverlapping,
} from 'date-fns';
import Joi from 'joi';
import { authenticatedFetch } from './fetch';

const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];

const applyFiltersToCustomerData = (customers, service, filters, data) => {
    let custom = [...customers];

    const keys = Object.keys(filters);

    if (service == 'Emergency Food Store') {
        const membershipFrom =
            filters?.['from-day']?.length > 0 &&
            filters?.['from-month']?.length > 0 &&
            filters?.['from-year']?.length > 0
                ? parse(
                      `${filters?.['from-day']}-${filters?.['from-month']}-${filters?.['from-year']}`,
                      'd-MMMM-yyyy',
                      new Date()
                  )
                : new Date(2000, 0, 1);
        const membershipTo =
            filters?.['to-day']?.length > 0 &&
            filters?.['to-month']?.length > 0 &&
            filters?.['to-year']?.length > 0
                ? parse(
                      `${filters?.['to-day']}-${filters?.['to-month']}-${filters?.['to-year']}`,
                      'd-MMMM-yyyy',
                      new Date()
                  )
                : new Date(2040, 0, 1);

        custom = custom.filter((rec) => {
            const compareDateFrom = rec['membership_date'] ?? new Date(2000, 0, 1);
            const compareDateTo = rec['membership_end_date'] ?? new Date(2040, 0, 1);

            return areIntervalsOverlapping(
                { start: membershipFrom, end: membershipTo },
                { start: compareDateFrom, end: compareDateTo }
            );
        });
    }

    // No of adults
    if (filters.adults_household?.length > 0) {
        custom = custom.filter((rec) => {
            return rec.adults_household == filters.adults_household;
        });
    }

    if (keys.find((key) => key.indexOf('adultage_') > -1)) {
        const ids = keys
            .filter((key) => key.indexOf('adultage_') > -1)
            .map((key) => {
                return {
                    id: parseInt(key.split('_')?.[1], 10),
                    val: parseInt(filters[key], 10),
                };
            });
        custom = custom.filter((rec) => {
            const check = ids
                .map((id) =>
                    data.customerAdultAgeData?.find(
                        (b) => b.customer_id == rec.id && b.id == id.id && b.val == id.val
                    )
                )
                .filter(function (element) {
                    return element !== undefined;
                });
            return check.length > 0;
        });
    }

    // No of children
    if (filters.children_household?.length > 0) {
        custom = custom.filter((rec) => {
            return rec.children_household == filters.children_household;
        });
    }

    if (keys.find((key) => key.indexOf('childage_') > -1)) {
        const ids = keys
            .filter((key) => key.indexOf('childage_') > -1)
            .map((key) => {
                return {
                    id: parseInt(key.split('_')?.[1], 10),
                    val: parseInt(filters[key], 10),
                };
            });
        custom = custom.filter((rec) => {
            const check = ids
                .map((id) =>
                    data.customerChildAgeData?.find(
                        (b) => b.customer_id == rec.id && b.id == id.id && b.val == id.val
                    )
                )
                .filter(function (element) {
                    return element !== undefined;
                });
            return check.length > 0;
        });
    }

    // Housing
    if (filters.housing?.length > 0) {
        custom = custom.filter((rec) => {
            return (
                rec.housing_id ==
                data.lookupData.housing.data.find((housing) => housing.name == filters.housing)?.id
            );
        });
    }

    // Employment
    if (filters.employment?.length > 0) {
        custom = custom.filter((rec) => {
            return (
                rec.employment_id ==
                data.lookupData.employment.data.find(
                    (employment) => employment.name == filters.employment
                )?.id
            );
        });
    }

    // Skills/Training
    if (filters.skills?.length > 0) {
        custom = custom.filter((rec) => {
            return rec.skills == filters.skills;
        });
    }

    // Work related help
    if (filters.work_related_help?.length > 0) {
        custom = custom.filter((rec) => {
            return rec.work_related_help == filters.work_related_help;
        });
    }

    // Health
    if (filters.health?.length > 0) {
        custom = custom.filter((rec) => {
            const healthDataByCustID = data.customerHealthData?.filter(
                (b) => b.customer_id === rec.id && b.name === filters.health
            );
            return healthDataByCustID?.length > 0;
        });
    }

    // Worried about keeping warm?
    if (filters.keeping_warm?.length > 0) {
        const check =
            filters.keeping_warm == 'Yes' ? 1 : filters.keeping_warm == 'No' ? 0 : undefined;
        custom = custom.filter((rec) => {
            return rec.energy_keeping_warm?.data?.[0] == check;
        });
    }

    // Energy bills/money Info?
    if (filters.energy_bills?.length > 0) {
        const check =
            filters.energy_bills == 'Yes' ? 1 : filters.energy_bills == 'No' ? 0 : undefined;
        custom = custom.filter((rec) => {
            return rec.energy_accessed_info?.data?.[0] == check;
        });
    }

    // Tried at home?
    if (filters.energy_try?.length > 0) {
        custom = custom.filter((rec) => {
            const energyDataByCustID = data.customerEnergyTryData?.filter(
                (b) => b.customer_id === rec.id && b.name === filters.energy_try
            );
            return energyDataByCustID?.length > 0;
        });
    }

    // Worried about paying?
    if (filters.bill?.length > 0) {
        custom = custom.filter((rec) => {
            const billDataByCustID = data.customerBillData?.filter(
                (b) => b.customer_id === rec.id && b.name === filters.bill
            );
            return billDataByCustID?.length > 0;
        });
    }

    // Outstanding debt?
    if (filters.outstanding_debt?.length > 0) {
        const check =
            filters.outstanding_debt == 'Yes'
                ? 1
                : filters.outstanding_debt == 'No'
                  ? 0
                  : undefined;
        custom = custom.filter((rec) => {
            return rec.debt_outstanding?.data?.[0] == check;
        });
    }

    // Claiming benefits?
    if (filters.benefits?.length > 0) {
        const check = filters.benefits == 'Yes' ? 1 : filters.benefits == 'No' ? 0 : undefined;
        custom = custom.filter((rec) => {
            return rec.debt_benefits?.data?.[0] == check;
        });
    }

    // Accessed help with money?
    if (filters.money?.length > 0) {
        const check = filters.money == 'Yes' ? 1 : filters.money == 'No' ? 0 : undefined;
        custom = custom.filter((rec) => {
            return rec.debt_help?.data?.[0] == check;
        });
    }

    // Other Assistance?
    if (filters.other_assistance?.length > 0) {
        custom = custom.filter((rec) => {
            return (
                (rec.debt_assistance_helping_agency?.data?.[0] == 1 &&
                    filters.other_assistance == 'Given helping agency details') ||
                (rec.debt_assistance_helping_appointment?.data?.[0] == 1 &&
                    filters.other_assistance == 'Made an appointment for client at helping agency')
            );
        });
    }

    console.log('custom', custom);
    console.log('filters', filters);

    return custom;
};

const getFirstDayOfYear = (year, weekday) => {
    let dayIdx = weekdays.findIndex((day) => day === weekday),
        d = new Date(year, 0, 1);

    d.setDate(dayIdx);

    while (d.getDay() !== dayIdx) {
        d.setDate(d.getDate() + 1);
    }
    return new Date(d.getTime());
};

const getWeekDayNumber = (weekDay) => {
    var days = {
        sunday: 0,
        monday: 1,
        tuesday: 2,
        wednesday: 3,
        thursday: 4,
        friday: 5,
        saturday: 6,
    };

    return days[weekDay.toLowerCase()];
};

const sortByFirstNumber = (a, b) => {
    const aa = parseInt(a.split('-')[0], 10);
    const bb = parseInt(b.split('-')[0], 10);
    if (aa < bb) {
        return -1;
    } else if (aa > bb) {
        return 1;
    }
    return 0;
};

const getDatesInRange = (startDate, endDate) => {
    return eachDayOfInterval({
        start: new Date(startDate),
        end: new Date(endDate),
    });
};

const removeDuplicatesByProperty = (arrOfObjects, prop) => {
    return arrOfObjects.filter(
        (obj1, i, arr) => arr.findIndex((obj2) => obj2[prop] === obj1[prop]) === i
    );
};

const convertLoadedDatesToJSONFormat = (loadedDates) => {
    if (loadedDates?.length > 0) {
        const final = [];
        loadedDates.forEach((record) => {
            const date = format(record.date, 'yyyy-MM-dd');
            const idx = final.findIndex((s) => s?.name == record.name);
            if (idx === -1) {
                final.push({
                    name: record.name,
                    data: [{ date, funding: record.funding }],
                });
            } else {
                final[idx].data.push({ date, funding: record.funding });
            }
        });
        return final;
    }

    return [];
};

const dataToSplitString = (data) => {
    return data
        .map((r) => r.name)
        .sort()
        .join('-');
};

const addCalendarDate = (cloned, action, dates, dateService, dateFunding) => {
    if (!Array.isArray(dates)) {
        dates = [dates];
    }

    // find array of dates for this service, or create if doesn't exist
    const idx = cloned.findIndex((service) => service.name === dateService);

    let found;
    if (idx > -1) {
        found = cloned[idx];
    } else {
        found = { name: dateService, data: [] };
    }

    console.log('action, dates, dateService, dateFunding', action, dates, dateService, dateFunding);

    dates.forEach((date) => {
        const formattedDate = format(date, 'yyyy-MM-dd');
        if (action === 'individual') {
            // Remove existing
            const foundIdx = found.data.findIndex((cal) => cal.date == formattedDate);
            if (foundIdx > -1) {
                found.data.splice(foundIdx, 1);
            } else {
                // Add new record
                found.data = found.data.concat({ date: formattedDate, funding: dateFunding });
            }
        } else if (action === 'add') {
            if (found.data.find((d) => d.date != formattedDate)) {
                found.data = found.data.concat({ date: formattedDate, funding: dateFunding });
            }
        } else {
            const foundIdx = found.data.findIndex((cal) => cal.date == formattedDate);
            if (foundIdx > -1) {
                found.data.splice(foundIdx, 1);
            }
        }
    });

    found.data = removeDuplicatesByProperty(found.data, 'date').sort();

    // remove existing item from array of dates
    if (idx > -1) {
        cloned[idx] = found;
    } else {
        cloned.push(found);
    }

    return cloned;
};

const loadCustomer = async (params, setTitle, setLoadingError, setValue, setLoading, getToken) => {
    const token = await getToken();

    if (params?.id && parseInt(params?.id, 10) > 0) {
        const customerQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        const customerServicesQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}/services`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        const adultAgeQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}/ages/adultAge`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        const childAgeQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}/ages/childAge`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        const customerHealthsQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}/healths`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        const customerEnergiesQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}/energies`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        const customerBillsQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}/bills`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        const customerDatesQuery = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params.id}/dates`,
            token
        ).then((res) => {
            if (res.status !== 200) {
                setLoadingError(res.text());
            } else {
                return res.json();
            }
        });

        if (customerQuery?.id) {
            setTitle(`Edit Customer: ${customerQuery.first_name} ${customerQuery.last_name}`);

            setValue('first_name', customerQuery.first_name);
            setValue('last_name', customerQuery.last_name);
            if (customerQuery.dob != null) {
                setValue('dobHidden', format(customerQuery.dob, 'd-MMMM-yyyy'));
            }
            setValue('genderHidden', customerQuery.gender === 'm' ? 'Male' : 'Female');
            setValue('address1', customerQuery.address1);
            setValue('postcode', customerQuery.postcode);
            setValue('countryHidden', customerQuery.country_id);
            setValue('telephone', customerQuery.telephone);
            setValue('maritalHidden', customerQuery.marital_id);
            setValue(
                'calendarHidden',
                JSON.stringify(convertLoadedDatesToJSONFormat(customerDatesQuery))
            );
            setValue('serviceHidden', dataToSplitString(customerServicesQuery));
            setValue('service_other', customerQuery.service_other);
            setValue('servicepartnership_other', customerQuery.service_partner);
            setValue('membership_no', customerQuery.membership_no);
            if (customerQuery.membership_date != null) {
                setValue('membershipHidden', format(customerQuery.membership_date, 'd-MMMM-yyyy'));
            }
            if (customerQuery.membership_end_date != null) {
                setValue(
                    'membershipEndHidden',
                    format(customerQuery.membership_end_date, 'd-MMMM-yyyy')
                );
            }
            setValue(
                'adultsAgeHidden',
                adultAgeQuery.map((a) => (a.val == null ? '' : a.val)).join('-')
            );
            setValue(
                'childAgeHidden',
                childAgeQuery.map((a) => (a.val == null ? '' : a.val)).join('-')
            );
            setValue('adults_householdHidden', customerQuery.adults_household);
            setValue('children_householdHidden', customerQuery.children_household);
            setValue('housingHidden', customerQuery.housing_id);
            setValue('housing_other', customerQuery.housing_other);
            setValue('employmentHidden', customerQuery.employment_id);
            setValue('employment_other', customerQuery.employment_other);
            setValue('skillsHidden', customerQuery.skills?.toString());
            setValue('workRelatedHelpHidden', customerQuery.work_related_help);
            setValue('workRelatedHelp_other', customerQuery.work_related_help_other);
            setValue('fundingHidden', customerQuery.funding_id);
            setValue('healthHidden', dataToSplitString(customerHealthsQuery));
            setValue('health_other', customerQuery.health_other);
            setValue(
                'energyKeepingWarmHidden',
                customerQuery.energy_keeping_warm?.data?.[0] == 1
                    ? 'True'
                    : customerQuery.energy_keeping_warm?.data?.[0] == 0
                      ? 'False'
                      : 'N/A'
            );
            setValue(
                'energyAccessedInfoHidden',
                customerQuery.energy_accessed_info?.data?.[0] == 1
                    ? 'True'
                    : customerQuery.energy_accessed_info?.data?.[0] == 0
                      ? 'False'
                      : 'N/A'
            );
            setValue('energyTryHidden', dataToSplitString(customerEnergiesQuery));
            setValue('energyTry_other', customerQuery.energy_tried_other);
            setValue('billHidden', dataToSplitString(customerBillsQuery));
            setValue('bill_other', customerQuery.bill_other);
            setValue(
                'debtOutstandingHidden',
                customerQuery.debt_outstanding?.data?.[0] == 1
                    ? 'True'
                    : customerQuery.debt_outstanding?.data?.[0] == 0
                      ? 'False'
                      : 'N/A'
            );
            setValue(
                'debtBenefitsHidden',
                customerQuery.debt_benefits?.data?.[0] == 1 ? 'true' : ''
            );
            setValue('debt_benefits_comments', customerQuery.debt_benefits_comments);
            setValue('debtHelpHidden', customerQuery.debt_help?.data?.[0] == 1 ? 'true' : '');

            const debtAssistanceCurrent = [];
            if (customerQuery.debt_assistance_helping_agency?.data?.[0] == 1) {
                debtAssistanceCurrent.push('Given helping agency details');
            }
            if (customerQuery.debt_assistance_helping_appointment?.data?.[0] == 1) {
                debtAssistanceCurrent.push('Made an appointment for client at helping agency');
            }
            setValue('debtAssistanceHidden', debtAssistanceCurrent.join('-'));

            setValue(
                'debt_assistance_helping_agency_comments',
                customerQuery.debt_assistance_helping_agency_comments
            );
            setValue(
                'debt_assistance_helping_appointment_comments',
                customerQuery.debt_assistance_helping_appointment_comments
            );
            setValue('payer_name', customerQuery.payer_name);
            setValue('payer_typeHidden', customerQuery.payer_type);
            setValue('payer_address', customerQuery.payer_address);
            setValue('payer_telephone_number', customerQuery.payer_telephone_number);
            setValue('payer_nok_name', customerQuery.payer_nok_name);
            setValue('payer_nok_contact_details', customerQuery.payer_nok_contact_details);
            setValue('other_info', customerQuery.other_info);
            setValue('enabledHidden', customerQuery.enabled?.data?.[0] == 1 ? 'true' : '');
        }
    }
    setLoading(false);
};

const dateIsNotNull = (date) => {
    return !(
        ['null', 'undefined'].includes(date.split('-')?.[0]) ||
        ['null', 'undefined'].includes(date.split('-')?.[1]) ||
        ['null', 'undefined'].includes(date.split('-')?.[2])
    );
};

const watchFieldsOnLoadCustomer = async (
    watchAllFields,
    populatingForm,
    setPopulatingForm,
    setValue,
    {
        setDobDay,
        setDobMonth,
        setDobYear,
        setGender,
        setCountry,
        setMarital,
        setService,
        setSelectedCalendarDates,
        setMembershipDay,
        setMembershipMonth,
        setMembershipYear,
        setMembershipEndDay,
        setMembershipEndMonth,
        setMembershipEndYear,
        setAdultsHousehold,
        setChildrenHousehold,
        setHousing,
        setEmployment,
        setSkills,
        setWorkRelatedHelp,
        setFunding,
        setHealth,
        setEnergyKeepingWarm,
        setEnergyAccessedInfo,
        setEnergyTry,
        setBill,
        setDebtOutstanding,
        setDebtBenefits,
        setShowDebtBenefitsCommentsOther,
        setDebtHelp,
        setDebtAssistance,
        setAdultAge,
        setChildAge,
        setPaymentType,
        setEnabled,
    },
    { countryData, maritalData, housingData, employmentData, fundingData }
) => {
    if (
        watchAllFields?.dobHidden?.length > 2 &&
        dateIsNotNull(watchAllFields?.dobHidden) &&
        !(populatingForm?.dob === true)
    ) {
        const dobParts = watchAllFields.dobHidden.split('-');
        setDobDay(dobParts[0]);
        setDobMonth(dobParts[1]);
        setDobYear(dobParts[2]);
        setPopulatingForm({ ...populatingForm, dob: true });
    }

    if (watchAllFields?.genderHidden?.length > 0 && !(populatingForm?.gender === true)) {
        setGender(watchAllFields?.genderHidden);
        setPopulatingForm({ ...populatingForm, gender: true });
    }

    if (watchAllFields?.payer_typeHidden?.length > 0 && !(populatingForm?.payer_type === true)) {
        setPaymentType(watchAllFields?.payer_typeHidden);
        setPopulatingForm({ ...populatingForm, payer_type: true });
    }

    if (
        watchAllFields?.countryHidden &&
        countryData?.data?.length > 0 &&
        !(populatingForm?.country === true)
    ) {
        if (isNumber(watchAllFields?.countryHidden)) {
            const countryValue = countryData?.data?.find(
                (c) => c.id == watchAllFields?.countryHidden
            );
            if (countryValue?.name) {
                setValue('countryHidden', countryValue.name);
                setCountry(countryValue.name);
                setPopulatingForm({ ...populatingForm, country: true });
            }
        }
    }

    if (
        watchAllFields?.maritalHidden &&
        maritalData?.data?.length > 0 &&
        !(populatingForm?.marital === true)
    ) {
        if (isNumber(watchAllFields?.maritalHidden)) {
            const maritalValue = maritalData?.data?.find(
                (c) => c.id == watchAllFields?.maritalHidden
            );
            if (maritalValue?.name) {
                setValue('maritalHidden', maritalValue.name);
                setMarital(maritalValue.name);
                setPopulatingForm({ ...populatingForm, marital: true });
            }
        }
    }

    if (watchAllFields?.calendarHidden?.length > 0 && !(populatingForm?.calendar === true)) {
        const calendarData = JSON.parse(watchAllFields?.calendarHidden);
        if (calendarData?.length > 0) {
            //setService(calendarData?.map((c) => c.name).join('-'));
            setSelectedCalendarDates(calendarData);
            setPopulatingForm({ ...populatingForm, calendar: true });
        }
    }

    if (
        watchAllFields?.membershipHidden?.length > 2 &&
        dateIsNotNull(watchAllFields?.membershipHidden) &&
        !(populatingForm?.membership === true)
    ) {
        const membershipParts = watchAllFields.membershipHidden.split('-');
        setMembershipDay(membershipParts[0]);
        setMembershipMonth(membershipParts[1]);
        setMembershipYear(membershipParts[2]);
        setPopulatingForm({ ...populatingForm, membership: true });
    }

    if (
        watchAllFields?.membershipEndHidden?.length > 2 &&
        dateIsNotNull(watchAllFields?.membershipEndHidden) &&
        !(populatingForm?.membershipEnd === true)
    ) {
        const membershipEndParts = watchAllFields.membershipEndHidden.split('-');
        setMembershipEndDay(membershipEndParts[0]);
        setMembershipEndMonth(membershipEndParts[1]);
        setMembershipEndYear(membershipEndParts[2]);
        setPopulatingForm({ ...populatingForm, membershipEnd: true });
    }

    if (
        watchAllFields?.adults_householdHidden?.toString().length > 0 &&
        watchAllFields?.adults_householdHidden.toString() != '0' &&
        !(populatingForm?.adults_household === true)
    ) {
        setAdultsHousehold(watchAllFields?.adults_householdHidden);
        setPopulatingForm({ ...populatingForm, adults_household: true });
    }

    if (
        watchAllFields?.children_householdHidden?.toString().length > 0 &&
        watchAllFields?.children_householdHidden.toString() != '0' &&
        !(populatingForm?.children_household === true)
    ) {
        setChildrenHousehold(watchAllFields?.children_householdHidden);
        setPopulatingForm({ ...populatingForm, children_household: true });
    }

    if (
        watchAllFields?.housingHidden &&
        housingData?.data?.length > 0 &&
        !(populatingForm?.housing === true)
    ) {
        if (isNumber(watchAllFields?.housingHidden)) {
            const housingValue = housingData?.data?.find(
                (c) => c.id == watchAllFields?.housingHidden
            );
            if (housingValue?.name) {
                setValue('housingHidden', housingValue.name);
                setHousing(housingValue.name);
                setPopulatingForm({ ...populatingForm, housing: true });
            }
        }
    }

    if (
        watchAllFields?.employmentHidden &&
        employmentData?.data?.length > 0 &&
        !(populatingForm?.employment === true)
    ) {
        if (isNumber(watchAllFields?.employmentHidden)) {
            const employmentValue = employmentData?.data?.find(
                (c) => c.id == watchAllFields?.employmentHidden
            );
            if (employmentValue?.name) {
                setValue('employmentHidden', employmentValue.name);
                setEmployment(employmentValue.name);
                setPopulatingForm({ ...populatingForm, employment: true });
            }
        }
    }

    if (
        watchAllFields?.serviceHidden?.toString().length > 0 &&
        !(populatingForm?.service === true)
    ) {
        setService(watchAllFields?.serviceHidden);
        setPopulatingForm({ ...populatingForm, service: true });
    }

    if (watchAllFields?.skillsHidden?.toString().length > 0 && !(populatingForm?.skills === true)) {
        setSkills(watchAllFields?.skillsHidden);
        setPopulatingForm({ ...populatingForm, skills: true });
    }

    if (
        watchAllFields?.workRelatedHelpHidden?.toString().length > 0 &&
        !(populatingForm?.work_related_help === true)
    ) {
        setWorkRelatedHelp(watchAllFields?.workRelatedHelpHidden);
        setPopulatingForm({ ...populatingForm, work_related_help: true });
    }

    if (
        watchAllFields?.fundingHidden &&
        fundingData?.data?.length > 0 &&
        !(populatingForm?.funding === true)
    ) {
        if (isNumber(watchAllFields?.fundingHidden)) {
            const fundingValue = fundingData?.data?.find(
                (c) => c.id == watchAllFields?.fundingHidden
            );
            if (fundingValue?.name) {
                setValue('fundingHidden', fundingValue.name);
                setFunding(fundingValue.name);
                setPopulatingForm({ ...populatingForm, funding: true });
            }
        }
    }

    if (watchAllFields?.healthHidden?.toString().length > 0 && !(populatingForm?.health === true)) {
        setHealth(watchAllFields?.healthHidden);
        setPopulatingForm({ ...populatingForm, health: true });
    }

    if (
        watchAllFields?.energyKeepingWarmHidden?.toString().length > 0 &&
        !(populatingForm?.energyKeepingWarm === true)
    ) {
        setEnergyKeepingWarm(watchAllFields?.energyKeepingWarmHidden);
        setPopulatingForm({ ...populatingForm, energyKeepingWarm: true });
    }

    if (
        watchAllFields?.energyAccessedInfoHidden?.toString().length > 0 &&
        !(populatingForm?.energyAccessedInfo === true)
    ) {
        setEnergyAccessedInfo(watchAllFields?.energyAccessedInfoHidden);
        setPopulatingForm({ ...populatingForm, energyAccessedInfo: true });
    }

    if (
        watchAllFields?.energyTryHidden?.toString().length > 0 &&
        !(populatingForm?.energyTry === true)
    ) {
        setEnergyTry(watchAllFields?.energyTryHidden);
        setPopulatingForm({ ...populatingForm, energyTry: true });
    }

    if (watchAllFields?.billHidden?.toString().length > 0 && !(populatingForm?.bill === true)) {
        setBill(watchAllFields?.billHidden);
        setPopulatingForm({ ...populatingForm, bill: true });
    }

    if (
        watchAllFields?.debtOutstandingHidden?.toString().length > 0 &&
        !(populatingForm?.debtOutstanding === true)
    ) {
        setDebtOutstanding(watchAllFields?.debtOutstandingHidden);
        setPopulatingForm({ ...populatingForm, debtOutstanding: true });
    }

    if (
        watchAllFields?.debtBenefitsHidden?.toString().length > 0 &&
        !(populatingForm?.debtBenefits === true)
    ) {
        setDebtBenefits(watchAllFields?.debtBenefitsHidden === 'true' ? true : false);
        setShowDebtBenefitsCommentsOther(
            watchAllFields?.debtBenefitsHidden === 'true' ? true : false
        );
        setPopulatingForm({ ...populatingForm, debtBenefits: true });
    }

    if (
        watchAllFields?.debtHelpHidden?.toString().length > 0 &&
        !(populatingForm?.debtHelp === true)
    ) {
        setDebtHelp(watchAllFields?.debtHelpHidden === 'true' ? true : false);
        setPopulatingForm({ ...populatingForm, debtHelp: true });
    }

    if (
        watchAllFields?.debtAssistanceHidden?.toString().length > 0 &&
        !(populatingForm?.debtAssistance === true)
    ) {
        setDebtAssistance(watchAllFields?.debtAssistanceHidden);
        setPopulatingForm({ ...populatingForm, debtAssistance: true });
    }

    if (
        watchAllFields?.adultsAgeHidden?.toString().length > 0 &&
        !(populatingForm?.adultsAge === true)
    ) {
        setAdultAge(watchAllFields?.adultsAgeHidden);
        setPopulatingForm({ ...populatingForm, adultsAge: true });
    }

    if (
        watchAllFields?.childAgeHidden?.toString().length > 0 &&
        !(populatingForm?.childAge === true)
    ) {
        setChildAge(watchAllFields?.childAgeHidden);
        setPopulatingForm({ ...populatingForm, childAge: true });
    }

    if (
        watchAllFields?.enabledHidden?.toString().length > 0 &&
        !(populatingForm?.enabled === true)
    ) {
        setEnabled(watchAllFields?.enabledHidden === 'true' ? true : false);
        setPopulatingForm({ ...populatingForm, enabled: true });
    }
};

const addUpdateCustomer = async (
    params,
    formData,
    user,
    getToken,
    {
        countryData,
        maritalData,
        housingData,
        employmentData,
        fundingData,
        billData,
        serviceData,
        energyTryData,
        healthData,
        adultAgeData,
        childAgeData,
    }
) => {
    console.log(formData);

    const token = await getToken();

    const schema = Joi.object({
        first_name: Joi.string()
            .required()
            .min(1)
            .max(128)
            .pattern(new RegExp(/^\w+(?:\s+\w+)*$/))
            .label('First Name'),
        last_name: Joi.string()
            .required()
            .min(1)
            .max(128)
            .pattern(new RegExp(/^\w+(?:\s+\w+)*$/))
            .label('Last Name'),
    }).unknown(true);

    const error = schema.validate(formData);
    const formErrors = error?.error?.details
        ?.map(() => 'first and last name can only contain alpha characters and spaces')
        .join(', ');

    if (formErrors?.length > 0) {
        return { formErrs: { keys: error?.error.details?.[0]?.path, val: formErrors } };
    }

    // Services/Dates
    let calendarServiceDates =
        formData.calendarHidden?.length > 1
            ? JSON.parse(formData.calendarHidden).map((service) => {
                  return {
                      ...service,
                      id: serviceData.data.find((c) => c.name === service.name).id,
                      data: service.data.map((date) => {
                          return {
                              ...date,
                              fundingId: fundingData.data.find((c) => c.name === date.funding).id,
                          };
                      }),
                  };
              })
            : [];

    const customerData = {
        user_id: user?.id,
        first_name: formData.first_name,
        last_name: formData.last_name,
        dob:
            formData.dobHidden?.length > 2 && dateIsNotNull(formData.dobHidden)
                ? format(parse(formData.dobHidden, 'dd-MMMM-yyyy', new Date()), 'yyyy-MM-dd')
                : null,
        gender:
            formData.genderHidden?.length > 1
                ? formData.genderHidden.substring(0, 1).toLowerCase()
                : 'm',
        country_id:
            formData.countryHidden?.length > 0
                ? countryData.data.find((c) => c.name === formData.countryHidden).id
                : null,
        address1: formData.address1,
        postcode: formData.postcode,
        telephone: formData.telephone,
        marital_id:
            formData.maritalHidden?.length > 0
                ? maritalData.data.find((c) => c.name === formData.maritalHidden).id
                : null,
        service_other: formData.service_other,
        service_partner: formData.servicepartnership_other,
        membership_no: formData.membership_no,
        membership_date:
            formData.membershipHidden?.length > 2 && dateIsNotNull(formData.membershipHidden)
                ? format(parse(formData.membershipHidden, 'dd-MMMM-yyyy', new Date()), 'yyyy-MM-dd')
                : null,
        membership_end_date:
            formData.membershipEndHidden?.length > 2 && dateIsNotNull(formData.membershipEndHidden)
                ? format(
                      parse(formData.membershipEndHidden, 'dd-MMMM-yyyy', new Date()),
                      'yyyy-MM-dd'
                  )
                : null,
        adults_household: formData.adults_householdHidden,
        children_household: formData.children_householdHidden,
        housing_id:
            formData.housingHidden?.length > 0
                ? housingData.data.find((c) => c.name === formData.housingHidden).id
                : null,
        housing_other: formData.housing_other,
        employment_id:
            formData.employmentHidden?.length > 0
                ? employmentData.data.find((c) => c.name === formData.employmentHidden).id
                : null,
        employment_other: formData.employment_other,
        skills: formData.skillsHidden?.length > 0 ? parseInt(formData.skillsHidden, 10) : null,
        work_related_help: formData.workRelatedHelpHidden,
        work_related_help_other: formData.workRelatedHelp_other,
        funding_id:
            formData.fundingHidden?.length > 0
                ? fundingData.data.find((c) => c.name === formData.fundingHidden).id
                : null,
        health_other: formData.health_other,
        energy_keeping_warm:
            formData.energyKeepingWarmHidden.toString() === 'True'
                ? true
                : formData.energyKeepingWarmHidden.toString() === 'False'
                  ? false
                  : null,
        energy_accessed_info:
            formData.energyAccessedInfoHidden.toString() === 'True'
                ? true
                : formData.energyAccessedInfoHidden.toString() === 'False'
                  ? false
                  : null,
        energy_tried_other: formData.energyTry_other,
        bill_other: formData.bill_other,
        debt_outstanding:
            formData.debtOutstandingHidden.toString() === 'True'
                ? true
                : formData.debtOutstandingHidden.toString() === 'False'
                  ? false
                  : null,
        debt_benefits: formData.debtBenefitsHidden.toString() === 'true' ? true : false,
        debt_benefits_comments: formData.debt_benefits_comments,
        debt_help: formData.debtHelpHidden.toString() === 'true' ? true : false,
        debt_assistance_helping_agency: formData.debtAssistanceHidden
            ?.split('-')
            .includes('Given helping agency details')
            ? true
            : false,
        debt_assistance_helping_agency_comments: formData.debt_assistance_helping_agency_comments,
        debt_assistance_helping_appointment: formData.debtAssistanceHidden
            ?.split('-')
            .includes('Made an appointment for client at helping agency')
            ? true
            : false,
        debt_assistance_helping_appointment_comments:
            formData.debt_assistance_helping_appointment_comments,
        other_info: formData.other_info,
        date_created: format(new Date(), 'yyyy-MM-dd'),
        date_modified: format(new Date(), 'yyyy-MM-dd'),
        billIds:
            formData.billHidden?.length > 1
                ? formData.billHidden
                      .split('-')
                      .map((bill) => billData.data.find((c) => c.name === bill).id)
                : [],
        serviceIds:
            formData.serviceHidden?.length > 1
                ? formData.serviceHidden
                      .split('-')
                      .map((service) => serviceData.data.find((c) => c.name === service).id)
                : [],
        energyTryIds:
            formData.energyTryHidden?.length > 1
                ? formData.energyTryHidden
                      .split('-')
                      .map((energyTry) => energyTryData.data.find((c) => c.name === energyTry).id)
                : [],
        healthIds:
            formData.healthHidden?.length > 1
                ? formData.healthHidden
                      .split('-')
                      .map((health) => healthData.data.find((c) => c.name === health).id)
                : [],
        adultAgeIds:
            formData.adultsAgeHidden?.length > 1
                ? formData.adultsAgeHidden.split('-').map((val, idx) => {
                      return { ageId: adultAgeData.data[idx].id, val };
                  })
                : [],
        childAgeIds:
            formData.childAgeHidden?.length > 1
                ? formData.childAgeHidden.split('-').map((val, idx) => {
                      return { ageId: childAgeData.data[idx].id, val };
                  })
                : [],
        calendarServiceDates: calendarServiceDates,
        payer_name: formData.payer_name,
        payer_type: formData.payer_typeHidden,
        payer_address: formData.payer_address,
        payer_telephone_number: formData.payer_telephone_number,
        payer_nok_name: formData.payer_nok_name,
        payer_nok_contact_details: formData.payer_nok_contact_details,
        enabled: formData.enabledHidden.toString() === 'true' ? true : false,
    };

    let response;

    // UPDATE OR ADD?
    if (params?.id && parseInt(params?.id, 10) > 0) {
        delete customerData.date_created;
        response = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/${params?.id}`,
            token,
            {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(customerData),
            }
        );
    } else {
        response = await authenticatedFetch(
            `${window.location.origin}/api/data/customer/add`,
            token,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(customerData),
            }
        );
    }

    if (!(response.status === 200 || response.status === 201)) {
        return { err: await response.text() };
    }

    return { formErrs: null, err: null, json: await response.json() };
};

const isNumber = (val) => {
    return /^[-]?\d+$/.test(val);
};

const thisWeeksDates = (date) => {
    const dates = [];
    const start = startOfWeek(date);
    for (var i = 0; i < 7; i++) {
        dates.push(addDays(start, i));
    }
    return dates;
};

const thisMonthsDates = (date) => {
    let dates = [];

    const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    dates = dates.concat(thisWeeksDates(startOfMonth));

    while (dates[dates.length - 1] < lastDayOfMonth(startOfMonth)) {
        dates = dates.concat(thisWeeksDates(addDays(dates[dates.length - 1], 1)));
    }

    dates = dates.filter((d) => d.getMonth() == date.getMonth());

    return dates;
};

const stringDateBetweenDays = (thisDays) => {
    const firstDate = thisDays?.[0],
        secondDate = thisDays?.[thisDays?.length - 1];

    if (firstDate && secondDate) {
        const formatter =
            firstDate.getYear() != secondDate.getYear() ||
            firstDate.getFullYear() != new Date().getFullYear()
                ? 'EEE do MMM yyyy'
                : 'EEE do MMM';
        return format(firstDate, formatter) + ' - ' + format(secondDate, formatter);
    }

    return '';
};

const nameSearch = (keywords, customers) => {
    const search = keywords.replace(/,/g, '');
    const filtered = customers
        ?.map((customer) => {
            return search?.split(' ').map((keyword) => {
                return {
                    id: customer.id,
                    name: customer.first_name + ' ' + customer.last_name,
                    keyword: keyword,
                    compare:
                        (customer.first_name + ' ' + customer.last_name)
                            ?.toLowerCase()
                            .indexOf(keyword?.toLowerCase()) > -1,
                };
            });
        })
        .flat()
        .filter((result) => result.compare === true)
        .map((result) => result.id);

    return customers?.filter((customer) => filtered.includes(customer.id));
};

const dateObjectToSqlDate = (dateObj, upper) => {
    let ret;

    try {
        ret = parse(`${dateObj.year} ${dateObj.month} ${dateObj.day}`, 'yyyy MMMM d', new Date());
        if (ret == 'Invalid Date') {
            throw ret;
        }
    } catch (err) {
        ret = lastDayOfMonth(
            parse(`${dateObj.year} ${dateObj.month} 1`, 'yyyy MMMM d', new Date())
        );
    }

    return upper ? format(addDays(ret, 1), 'yyyy-MM-dd') : format(ret, 'yyyy-MM-dd');
};

const fields = [
    { name: 'first_name', label: 'First Name' },
    { name: 'last_name', label: 'Last Name' },
    { name: 'dob', label: 'DOB', isDate: true },
    { name: 'gender', label: 'Gender', custom: true },
    { name: 'country_id', label: 'Country of Birth', custom: true },
    { name: 'address1', label: 'Address 1', width: 25 },
    { name: 'postcode', label: 'Postcode' },
    { name: 'telephone', label: 'Telephone' },
    { name: 'marital_id', label: 'Marital Status', custom: true },
    { name: 'service', label: 'Service(s)', width: 35, custom: true },
    { name: 'service_other', label: 'Service - Other', width: 25 },
    {
        name: 'service_partner',
        label: 'Service - Professional worker/partnership working',
        width: 40,
    },
    { name: 'attendance', label: 'Attendance', width: 35, custom: true },
    { name: 'membership_no', label: 'Membership No (Food Store)', width: 25 },
    { name: 'membership_date', label: 'Membership Date (Food Store)', width: 25, isDate: true },
    {
        name: 'membership_end_date',
        label: 'Membership End Date (Food Store)',
        width: 25,
        isDate: true,
    },
    { name: 'adults_household', label: 'Number of adults (incl. client)', width: 35 },
    { name: 'adults_household_age', label: 'Number of adults by age', width: 35, custom: true },
    { name: 'children_household', label: 'Number of children', width: 25 },
    { name: 'children_household_age', label: 'Number of children by age', width: 25, custom: true },
    { name: 'housing_id', label: 'Housing Type', custom: true },
    { name: 'housing_other', label: 'Housing Type - Other', width: 20 },
    { name: 'employment_id', label: 'Employment', custom: true },
    { name: 'employment_other', label: 'Employment - Other', width: 20 },
    { name: 'skills', label: 'Skills/Training' },
    { name: 'work_related_help', label: 'Work related help already accessed', width: 35 },
    {
        name: 'work_related_help_other',
        label: 'Work related help already accessed - Other',
        width: 35,
    },
    { name: 'funding_id', label: 'Funding/Mental Health', width: 20, custom: true },
    { name: 'health', label: 'Mental/general health requirements', width: 30, custom: true },
    { name: 'health_other', label: 'Mental/general health requirements - Other', width: 35 },
    { name: 'energy_keeping_warm', label: 'Keeping warm at home', width: 25, isBit: true },
    {
        name: 'energy_accessed_info',
        label: 'Accessed info about energy bills',
        width: 35,
        isBit: true,
    },
    { name: 'energy_tried', label: 'Tried at home', width: 25, custom: true },
    { name: 'energy_tried_other', label: 'Tried at home - Other', width: 25 },
    { name: 'bill', label: 'Worried paying rent/bills', width: 30, custom: true },
    { name: 'bill_other', label: 'Worried paying rent/bills - Other', width: 35 },
    { name: 'debt_outstanding', label: 'Outstanding debt', width: 20, isBit: true },
    { name: 'debt_benefits', label: 'Claiming benefits', width: 20, isBit: true },
    { name: 'debt_benefits_comments', label: 'Claiming benefits - Comments', width: 30 },
    { name: 'debt_help', label: 'Accessed help with money', width: 25, isBit: true },
    {
        name: 'debt_assistance_helping_agency',
        label: 'Assistance - Helping agency details',
        width: 30,
        isBit: true,
    },
    {
        name: 'debt_assistance_helping_agency_comments',
        label: 'Assistance - Helping agency details - Comments',
        width: 45,
    },
    {
        name: 'debt_assistance_helping_appointment',
        label: 'Assistance - Appointment for client at helping agency',
        width: 45,
        isBit: true,
    },
    {
        name: 'debt_assistance_helping_appointment_comments',
        label: 'Assistance - Appointment for client at helping agency - Comments',
        width: 50,
    },
    { name: 'payer_name', label: 'Payer Name', width: 25 },
    { name: 'payer_type', label: 'Payment Type', width: 25 },
    { name: 'payer_address', label: 'Payer address', width: 40 },
    { name: 'payer_telephone_number', label: 'Payer telephone number', width: 20 },
    { name: 'payer_nok_name', label: 'Next of kin name', width: 25 },
    { name: 'payer_nok_contact_details', label: 'Next of kin contact details', width: 40 },
    { name: 'other_info', label: 'Other', width: 40 },
    { name: 'enabled', label: 'Active', width: 20, isBit: true },
];

export {
    months,
    weekdays,
    fields,
    applyFiltersToCustomerData,
    isNumber,
    thisWeeksDates,
    thisMonthsDates,
    stringDateBetweenDays,
    getDatesInRange,
    getFirstDayOfYear,
    getWeekDayNumber,
    sortByFirstNumber,
    addCalendarDate,
    loadCustomer,
    watchFieldsOnLoadCustomer,
    addUpdateCustomer,
    convertLoadedDatesToJSONFormat,
    nameSearch,
    dateObjectToSqlDate,
};
